import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = (props) => {
  const data = useStaticQuery(graphql`
    query imageQuery{
      allFile {
        edges {
          node {
            relativePath
            publicURL
            name
            childImageSharp {
              fluid(maxWidth: 1000, quality: 64) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const image = data.allFile.edges.find((img) => {
    return img.node.relativePath.includes(props.name)
  })

  return (<Img fluid={image.node.childImageSharp.fluid} alt={props.alt} />)
}

export default Image
